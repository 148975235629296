export const displayContentHintConfig = {
  'serviceUnit-service': { label: 'reservationCalendarDisplaySettingModal.displayContentHintConfig.serviceUnitService.title', value: 'serviceUnit-service' },
  'resource-service': { label: 'reservationCalendarDisplaySettingModal.displayContentHintConfig.resrouceService.title', value: 'resource-service' },
  'member-service': { label: 'reservationCalendarDisplaySettingModal.displayContentHintConfig.memberService.title', value: 'member-service' },
  'serviceUnit-member': { label: 'reservationCalendarDisplaySettingModal.displayContentHintConfig.memberServiceUnit.title', value: 'serviceUnit-member' },
}
export const displayColorHintConfig = {
  'order-status': { label: 'reservationCalendarDisplaySettingModal.displayColorHintConfig.orderStatus.title', value: 'serviceUnit-service' },
  'service-unit': { label: 'reservationCalendarDisplaySettingModal.displayColorHintConfig.serviceUnit.title', value: 'member-service' },
}

export const calendarConfigs = {
  zh: {
    code: 'zh-tw',
    buttonText: {
      today: '今天',
      month: '月檢視',
      week: '週檢視',
      day: '天檢視',
      list: '列表',
    },
  },
  en: {
    code: 'en',
    buttonText: {
      today: 'Today',
      month: 'Month View',
      week: 'Week View',
      day: 'Day View',
      list: 'List',
    },
  },
  jp: {
    code: 'ja',
    buttonText: {
      today: '今日',
      month: '月の表示',
      week: '週の表示',
      day: '日の表示',
      list: 'リスト',
    },
  },
  default: {
    code: 'zh-tw',
    buttonText: {
      today: '今天',
      month: '月檢視',
      week: '週檢視',
      day: '天檢視',
      list: '列表',
    },
  },
}
